import {Organization} from "./organization";
import {request} from "./req";
import {Project} from "./projects";

export type Transaction = {
  id: string;
  createdAt: string;
  date?: string;
  amount: string;
  type: TransactionType;
  project?: Project;
  organization?: Organization;
};

export type TransactionType = "deposit" | "investment" | undefined;

export function listTransactions(): Promise<Transaction[]> {
  return request("get", "transactions");
}

export function invest(
  organizationId: string,
  projectId: string | null,
  amount: number
): Promise<Transaction> {
  return request("post", "transactions/invest", {
    organizationId,
    projectId,
    amount,
  });
}
