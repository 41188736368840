import React, {useState} from "react";
import styles from "../../components/auth.module.css";
import {Controller, useForm} from "react-hook-form";
import {Grid} from "@mui/material";
import {ErrorMessage} from "@hookform/error-message";
import TextField from "@mui/material/TextField";
import {createUser} from "../../api/users";
import {numbersOnly} from "../../helpers/numbers";
import {Container} from "../../components/Container";
import {CAPTCHA_ID} from "../base";
import Auth from "../../components/Auth";
import {PRIMARY} from "../colors";
import MaskedTextField from "../../components/MaskedTextField";
import {Button} from "../../components/Button";
import { useNavigate } from "react-router";

export default function Signup() {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: {errors},
    clearErrors,
  } = useForm();
  const [auth, setAuth] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(undefined);
  const [recaptchaEnabled, setRecaptchaEnabled] = useState<boolean>(true);

  const successCallback = async (user: any, _phone: string, email?: string) => {
    setUser({...user, email: email});
    setAuth(false);
  };

  const onSignup = async (payload: any) => {
    setLoading(true);
    const phone = user.providerData[0].uid;
    const created = await createUser(
      payload.name,
      numbersOnly(payload.cpf),
      phone,
      user.email,
    );
    console.log("created", created);
    setLoading(false);
    navigate("/home", { replace: true} );
  };

  return (
    <main className={styles.main}>
      <Container fullHeight={true}>
        <h2 className={styles.title}>Criar conta</h2>
        <h3 className={styles.description}>
          Insira o seus dados para criar uma conta
        </h3>
        {recaptchaEnabled && (
          <div
            id={CAPTCHA_ID}
            style={{height: "75px", width: "305px", margin: "0 auto"}}
          />
        )}
        {auth ? (
          <Auth
            successCallback={successCallback}
            withEmail={false}
            captchaId={CAPTCHA_ID}
            setRecaptchaEnabled={setRecaptchaEnabled}
          />
        ) : (
          <>
            <form onSubmit={handleSubmit(onSignup)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={5}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}: any) => (
                      <TextField
                        InputProps={{
                          className: styles.input,
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#fff",
                            backgroundColor: PRIMARY,
                            padding: "5px",
                            borderRadius: "5px",
                          },
                        }}
                        sx={{input: {color: "white", textIndent: "15px"}}}
                        onChange={onChange}
                        onKeyUp={onChange}
                        value={value}
                        required
                        label="Nome"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name && "Nome é obrigatório"}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="cpf"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, value}}: any) => (
                      <MaskedTextField
                        InputProps={{
                          className: styles.input,
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#fff",
                            backgroundColor: PRIMARY,
                            padding: "5px",
                            borderRadius: "5px",
                          },
                        }}
                        sx={{input: {color: "white", textIndent: "15px"}}}
                        mask={"000.000.000-00"}
                        onChange={onChange}
                        onKeyUp={onChange}
                        value={value}
                        required
                        label="CPF"
                        variant="outlined"
                        error={!!errors.cpf}
                        helperText={errors.cpf && "CPF é obrigatório"}
                        fullWidth
                      />
                    )}
                  />
                  <Button loading={loading}>Criar conta</Button>
                  <ErrorMessage errors={errors} name="singleErrorInput"/>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Container>
    </main>
  );
}
