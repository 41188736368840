import styled from "styled-components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ReactLoading from "react-loading";
import { useEffect, useState } from "react";
import {
  GRAY,
  TERTIARY,
  TERTIARY_DARK,
  SECONDARY,
  WHITE,
  PRIMARY,
} from "../app/colors";

type BtnContainerProps = {
  secondary?: boolean;
  tertiary?: boolean;
  $isCancel?: boolean;
  inverted?: boolean;
};

const BtnContainer = styled.button<BtnContainerProps>`
  width: 100%;
  height: ${({ $isCancel }) => ($isCancel ? "50px" : "60px")};
  background: ${({ inverted, secondary, $isCancel }: any) =>
    inverted || $isCancel
      ? "rgba(255,255,255,.65)"
      : secondary
      ? SECONDARY
      : TERTIARY};
  color: ${({ inverted, secondary, $isCancel }: any) =>
    inverted ? (secondary ? SECONDARY : TERTIARY) : $isCancel ? GRAY : PRIMARY};
  border-radius: 10px;
  border: ${({ inverted, secondary }: any) =>
    inverted
      ? secondary
        ? `1px solid ${SECONDARY}`
        : `1px solid ${TERTIARY}`
      : "0"};
  cursor: pointer;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  font-size: 18px;
  margin: 10px auto;
  opacity: ${({ $isCancel }) => ($isCancel ? "0.65" : "1")};

  &:hover {
    background: ${({ inverted, secondary, $isCancel }: any) =>
      inverted
        ? secondary
          ? "rgba(0,173,204,0.05)"
          : "rgba(112,176,0,0.05)"
        : $isCancel
        ? "#ececec"
        : TERTIARY_DARK};
  }

  &:disabled {
    background: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
  }
`;

const Loading = styled(ReactLoading)`
  display: flex;
  height: 50px;
`;

interface ButtonProps {
  text?: string;
  loading?: boolean;
  inverted?: boolean;
  secondary?: boolean;
  confirmable?: boolean;
  cancel?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  form?: any;
  children?: React.ReactNode;
  validateEmpty?: any;
}

export const Button = (props: ButtonProps) => {
  const text = props.text || "Button";
  const [confirming, setConfirming] = useState(false);

  const [propsForm, setPropsForm] = useState({
    type: props.onClick ? "button" : "submit",
  });

  useEffect(() => {
    setPropsForm((current) => ({
      ...current,
      onClick: props.confirmable ? confirmCallback : props.onClick,
    }));
  }, []);

  const confirmCallback = (event: any) => {
    event.preventDefault();
    setConfirming(true);

    setPropsForm((current) => ({
      ...current,
      onClick: confirmed,
    }));
  };

  const confirmed = () => {
    setConfirming(false);
    props.onClick && props.onClick();
  };

  const child = props.children ? (
    props.children
  ) : (
    <p>
      <strong>{text}</strong>
    </p>
  );

  return (
    <>
      {confirming && <p>Confirmar investimento?</p>}
      {/* @ts-ignore */}
      <BtnContainer
        inverted={props.inverted && !confirming}
        secondary={props.secondary && !confirming}
        $isCancel={props.cancel}
        disabled={props.disabled || props.loading}
        {...propsForm}
      >
        {confirming ? <p>Confirmar</p> : child}
        {props.loading ? (
          <Loading
            type={"bubbles"}
            height={"50px"}
            width={"50px"}
            color={"#fff"}
          />
        ) : props.cancel ? (
          <></>
        ) : (
          <ChevronRightIcon />
        )}
      </BtnContainer>
    </>
  );
};
