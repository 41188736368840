import {request} from "./req";
import {Organization} from "./organization";

export type IncentiveLaw = {
  id: string
  name: string
  max: number
}

export type Project = {
  id: string
  name: string
  description: string // TODO: ADD TO TYPESCRIPT
  max: number
  current: number
  active: boolean
  incentiveLaw: IncentiveLaw
  organization: Organization
}

export async function listProjects() {
  return await request("get", "projects");
}

export async function getProjectById(id: string) {
  return await request("get", `projects/${id}`);
}