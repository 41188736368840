import React, {useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {Box, Grid} from "@mui/material";
import styles from "../../../page.module.css";
import {
  Organization,
  getOrganizationById,
} from "../../../../api/organization";
import Centered from "../../../../components/Centered";

export function TransparencyContent({organizationId}: any) {
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );

  useEffect(() => {
    getOrganizationById(organizationId).then((organization) => {
      setOrganization(organization);
    });
  }, [organizationId]);

  if (!organization) {
    return (
      <Centered>
        <ReactLoading type="spinningBubbles" color="#6bd666"/>
      </Centered>
    );
  }

  console.log(organization.presentation)
  return (
    <>
      <Grid
        container
        spacing={0}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={2}>
          <div className={styles.bigLogoContainer}>
            <img
              className="transparency-logo"
              src={organization.logo}
              alt={organization.name}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <h2 className={styles.title}>{organization.name}</h2>
        </Grid>
      </Grid>
      <Box mt={4} mb={4}>
        <div
          dangerouslySetInnerHTML={{
            __html: organization.presentation?.replace(/\n/g, "<br/>") || "",
          }}
        />
      </Box>
    </>
  );
}
