import {getPhone, getToken} from "../app/storage";

const baseUrl = process.env.NODE_ENV !== "development" ? "https://default-mbbdgak5xq-uc.a.run.app/" : "http://127.0.0.1:5001/giv-app-123/us-central1/default/";


const isTokenExpired = () => {
  const token = getToken();
  if (!token) return true;

  const payload = token.split(".")[1];
  const decoded = JSON.parse(atob(payload));
  const now = Date.now() / 1000;
  return decoded.exp < now;
}

export const request = async (
  method: string,
  url: string,
  data?: any,
  options?: any
) => {

  if (isTokenExpired()) {
    console.info("Token expired, redirecting to login");
    window.location.href = "/login";
  }

  try {
    const resp = await fetch(baseUrl + url, {
      ...options,
      method: method,
      mode: "cors",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
        authorization: getToken() || "",
        phone: getPhone() || "",
      },
      body: data ? JSON.stringify(data) : undefined,
    }).then((res) => {
      if (!res.ok) {
        console.log("RES", res)
        if (res.status === 401) {
          window.location.href = "/login";
          return;
        }
      }

      return res.json();
    });
    return resp;
  } catch (e) {
    console.error("> error", e);
    return e;
  }
};
