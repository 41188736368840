import "./InvestmentPortfolioAndWallet.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faHandsHoldingChild,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import {UserBalance} from "../../../../api/users";
import {toReal} from "../../../../helpers/formatters";

type InvestmentPortfolioAndWalletProps = {
  user?: UserBalance;
};
export default function InvestmentPortfolioAndWallet(
  props: InvestmentPortfolioAndWalletProps
) {
  const {user} = props;

  return (
    <div className="investiment-cards-container">
      <div className="investiment-cards">
        <div className="wallet-card dark">
          <FontAwesomeIcon icon={faWallet} size="sm"/>
          <h3 className="wallet-card-header-title">Saldo disponível</h3>
          {user ? (
            <div>
              <h4 className="wallet-card-value">
                <small>R$</small> {toReal(user.balance || 0)}
              </h4>
            </div>
          ) : (
            <Skeleton
              baseColor="black"
              highlightColor="#242424"
              height={50}
              width={150}
            />
          )}
          {/* <button className="grey-action-button">Adicionar dinheiro</button> */}
        </div>
        <div className="wallet-card">
          <FontAwesomeIcon icon={faHandsHoldingChild} size="sm"/>
          <h3 className="wallet-card-header-title">Valor doado</h3>
          {user ? (
            <h4 className="wallet-card-value">
              <small>R$</small> {toReal(user.invested || 0)}
            </h4>
          ) : (
            <Skeleton
              baseColor="#c1ec45"
              highlightColor="#d4f27c"
              height={50}
              width={150}
            />
          )}
        </div>
      </div>
    </div>
  );
}
