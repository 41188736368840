import "./StatementList.scss";
import moment from "moment";
import {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import {Transaction, TransactionType} from "../../../../api/transaction";
import {User, UserBalance} from "../../../../api/users";
import BalanceCard from "../../../../components/BalanceCard/BalanceCard";
import {readableDate, toReal} from "../../../../helpers/formatters";

const translateType = (type: "deposit" | "investment" | undefined) => {
  switch (type) {
    case "deposit":
      return "Depósito";
    case "investment":
      return "Investimento";
    default:
      return "Indefinido";
  }
};

type StatementProps = {
  user?: UserBalance;
  transactions?: Transaction[];
};

const convertTransactionType = (transactionType: TransactionType) => {
  if (transactionType === "deposit") return "Deposito";

  return "Investimento";
};

const groupBy = (array: any[], key: any) => {
  return array.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const formatTransactions = (transactions: Transaction[]) => {
  return transactions.map((trans) => {
    const date = new Date(trans.createdAt);

    const adjustedDay =
      date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const adjustedMonth =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

    return {
      ...trans,
      date: `${date.getFullYear()}-${adjustedMonth}-${adjustedDay}`,
    };
  });
};

const mergeDates = (transactions: Transaction[]): MergedTransactions => {
  return groupBy(formatTransactions(transactions), "date") as any;
};

const buildTransactions = (transactions: Transaction[]): MergedTransactions => {
  return mergeDates(transactions);
};

export default function Statement(props: StatementProps) {
  const {user, transactions: propTransactions} = props;

  const [transactions, setTransactions] = useState<MergedTransactions>({});

  useEffect(() => {
    setTransactions(buildTransactions(propTransactions || []));
  }, [propTransactions]);

  return (
    <div className="statement-list">
      <BalanceCard balance={user?.balance}/>

      <div>
        {Object.keys(transactions).map((date: string) => {
          return (
            <div key={date} className="statement-list-day">
              <h3 className="statement-list-day-title">{readableDate(date)}</h3>
              {(transactions[date] || []).map((transaction: Transaction) => (
                <div key={transaction.id} className="statement-transaction">
                  <div className="statement-transaction-description">
                    <h3>{convertTransactionType(transaction.type)}</h3>
                    {transaction.organization && (
                      <span className="statement-transaction-description-organization">
                        {transaction.organization.name}
                      </span>
                    )}
                  </div>

                  <div className="statement-transaction-amount">
                    <span>
                      <small>R$</small> {toReal(transaction.amount)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>

      {!user && <Skeleton style={{marginTop: 15}} height={70} count={10}/>}
    </div>
  );
}

interface MergedTransactions {
  [key: string]: Transaction[];
}
