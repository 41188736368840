// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth, onIdTokenChanged} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {Messaging, getMessaging} from "firebase/messaging";
import {updateToken} from "./app/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCrV3K6fzSvCcNizfGO5N1hMedq51XV0T0",
  authDomain: "giv-app-123.firebaseapp.com",
  projectId: "giv-app-123",
  storageBucket: "giv-app-123.appspot.com",
  messagingSenderId: "1015866122681",
  appId: "1:1015866122681:web:20418d5071ed452888d668",
  measurementId: "G-ZVG9X2SMGC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, {
  name: "giv",
});

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

let analytics;
let messaging: Messaging | null;

if (process.env.NODE_ENV !== "development") {
  if (app.name && typeof window !== "undefined") {
    try {
      analytics = getAnalytics(app);
      messaging = getMessaging(app);
    } catch (e) {
      console.error("Error initializing Firebase services");
      console.error(e);
    }
  }
}


onIdTokenChanged(auth, (user) => {
  if (user) {
    user.getIdToken().then((idToken) => {
      updateToken(idToken);
    });
  }
});

export {analytics, messaging};
export default app;
