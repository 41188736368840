import { Portfolio } from "./portfolio";
import { request } from "./req";
import { Transaction } from "./transaction";

export type User = {
  id: string;
  name: string;
  cpf: string;
  phone: string;
  balance: number;
  invested: number;
  investments: Transaction[];
};

export type UserBalance = {
  id: string
  name: string
  cpf: string;
  phone: string;
  balance: number
  invested: number
  investments: Transaction[]
  portfolio: Portfolio[]
}

export const getBalance = async () : Promise<UserBalance> => {
  return await request("get", `users`);
};

export const createUser = async (name: string, cpf: string, phone: string, email?: string) => {
  const response = await request("post", `users`, {
    name,
    cpf,
    phone,
    email,
  });

  return response;
};
