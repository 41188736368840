import React from "react";
import {Project} from "../../../../api/projects";
import './index.scss'
import {LinearProgress} from "@mui/material";
import {toReal} from "../../../../helpers/formatters";

interface ProjectItemProps {
  project: Project
  setSelectedProject: (project: Project) => void
}

export const ProjectItem = ({project, setSelectedProject}: ProjectItemProps) => {
  let percentage = parseInt((((project.current || 1) / (project.max || 1)) * 100).toFixed(0))

  if (percentage > 100) {
    percentage = 100
  }

  console.log("project.active", project.active)

  return (
    <div
      key={project.id}
      className={`project-item ${project.active ? '' : 'disabled'}`}
      onClick={() => setSelectedProject(project)}
    >
      <div className="project-item-name">
        {project.name}
      </div>
      <div className="project-item-organization">
        {project.organization.name}
      </div>
      {/*<div className="project-item-category">*/}
      {/*  {project.incentiveLaw.name}*/}
      {/*</div>*/}
      <div className="project-item-progress">
        <LinearProgress value={percentage} valueBuffer={100} variant={"buffer"} color='success' sx={
          {width: '180px', height: '8px', borderRadius: '2px'}
        }/>
        R$ {toReal(project.current)} / R$ {toReal(project.max)}
      </div>
    </div>
  )
}
