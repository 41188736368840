import React, {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import "./page.scss";
import {listProjects, Project} from "../../api/projects";
import {ProjectItem} from "./components/ProjectItem";
import FullModal from "../../components/FullModal/FullModal";
import ProjectDetails from "./components/ProjectDetails";

export default function Projects() {
  const [projects, setProjects] = useState<Project[]>();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    listProjects().then((projects) => {
      setProjects(projects || []);
      setLoading(false)
    });
  }, []);

  if (selectedProject) {
    return (
      <FullModal
        order={{ id: "project-detail", sequence: 1 }}
        closeModal={() => setSelectedProject(null)}
      >
        <ProjectDetails project={selectedProject} />
      </FullModal>
    );
  }

  return (
    <div className="projects">
      <div className="projects-header">
        <h1>Projetos</h1>
      </div>
      <div className="projects-list">
        {loading ?
          (
            <Skeleton
              height={110}
              count={10}
              style={{borderRadius: "6px", marginBottom: "10px"}}
            />
          ) : (
            projects?.map((project) => (
              <ProjectItem key={`${project.id}`} project={project} setSelectedProject={setSelectedProject}/>
            ))
          )
        }
      </div>
    </div>
  )
}
