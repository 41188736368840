export function saveUser(user: any) {
  try {
    const serializedUser = JSON.stringify(user);
    localStorage.setItem("user", serializedUser);
  } catch {
    // ignore write errors
  }
}

export function savePhone(phone: string) {
  try {
    localStorage.setItem("phone", phone);
  } catch {
    // ignore write errors
  }
}

export function getUser() {
  try {
    const serializedUser = localStorage.getItem("user");
    if (serializedUser === null) {
      return undefined;
    }
    return JSON.parse(serializedUser);
  } catch {
    return undefined;
  }
}

export function getToken() {
  const user = getUser();
  if (user) {
    return user.accessToken || user.stsTokenManager?.accessToken;
  }
  return undefined
}

export function updateToken(token: string) {
  console.log("updateToken", token.slice(0, 8));
  const user = getUser();
  if (user) {
    user.accessToken = token;
    saveUser(user);
  }
}

export function getPhone() {
  try {
    const phone = localStorage.getItem("phone");
    if (phone === null) {
      return undefined;
    }
    return phone;
  } catch {
    return undefined;
  }
}

export function removeUser() {
  localStorage.removeItem("user");
}