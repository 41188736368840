import { useCallback, useState, useEffect } from "react";
import OrgListItem from "./components/OrgList/OrgList";
import Skeleton from "react-loading-skeleton";
import OrgDetails from "./components/OrgDetails/OrgDetails";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./page.scss";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Organization, listOrganizations } from "../../api/organization";
import FullModal from "../../components/FullModal/FullModal";

export default function Marketplace() {
  const [ngos, setNgos] = useState<Organization[]>();
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const [filteredOrgs, setFilteredOrgs] = useState<Organization[] | null>(null);
  const [filteredName, setFilteredName] = useState<string>("");
  const [selectedCategoryToFilter, setSelectedCategoryToFilter] =
    useState<string>("");
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  useEffect(() => {
    listOrganizations().then((ngos) => {
      setNgos(ngos || []);
      setFilteredOrgs(ngos || []);
    });
  }, []);

  const handleFilterOrgs = useCallback(
    ({ value: filterValue }: any) => {
      setSelectedCategoryToFilter(() => {
        if (!ngos) return filterValue;

        if (!filterValue) {
          setFilteredOrgs(ngos);
          return filterValue;
        }

        setFilteredName("");
        setFilteredOrgs(ngos.filter((a) => a.category?.name === filterValue));

        return filterValue;
      });
    },
    [ngos]
  );

  const handleFilterByName = useCallback(
    ({ target: { value: filterValue } }: any) => {
      setFilteredName(() => {
        if (!ngos) return;

        if (!filterValue) {
          setFilteredOrgs(ngos);
          return filterValue;
        }

        setSelectedCategoryToFilter("");

        const filteredOrgs = ngos.filter((a) =>
          a.name.toLowerCase().includes(filterValue.toLowerCase())
        );

        setFilteredOrgs(filteredOrgs);

        return filterValue;
      });
    },
    [ngos]
  );

  const categoriesOptions = [
    ...new Set(ngos?.map((a) => a.category?.name)),
  ].map((ctg) => ({ label: ctg || "", value: ctg || "" }));

  if (selectedOrg) {
    return (
      <FullModal
        order={{ id: "org-detail", sequence: 1 }}
        closeModal={() => setSelectedOrg(null)}
      >
        <OrgDetails organization={selectedOrg} />
      </FullModal>
    );
  }

  return (
    <div className="org-list">
      {isFiltering ? (
        <div className="org-list-filters">
          <input
            className="giv-input"
            placeholder="Nome da ONG"
            value={filteredName}
            onChange={handleFilterByName}
          />

          <ReactSelect
            className="org-list-filters-category"
            placeholder="Categoria"
            options={[{ label: "Todos", value: "" }, ...categoriesOptions]}
            onChange={handleFilterOrgs}
            value={{
              label: selectedCategoryToFilter || "Todos",
              value: selectedCategoryToFilter,
            }}
            isSearchable={false}
          />
        </div>
      ) : (
        <button
          className="org-list-filter-button"
          onClick={() => setIsFiltering(true)}
        >
          <FontAwesomeIcon icon={faFilter} size="xl" />
          <span>Filtrar</span>
        </button>
      )}

      {filteredOrgs ? (
        filteredOrgs.map((org) => (
          <OrgListItem
            key={org.id}
            organization={org}
            onSelectOrg={(_org) => setSelectedOrg(_org)}
          />
        ))
      ) : (
        <Skeleton
          height={110}
          count={10}
          style={{ borderRadius: "6px", marginBottom: "10px" }}
        />
      )}
    </div>
  );
}
